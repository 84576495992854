import Image from 'next/image';
import { ForwardedRef, forwardRef } from 'react';

const IMAGE_REPOSITORY_URL = process.env.NEXT_PUBLIC_IMAGE_REPOSITORY_URL
export interface ImageProps {
  title: string
  src?: string | undefined
  href?: string | undefined
  className?: string | undefined
  width?: number | undefined
  height?: number | undefined
  priority?: boolean | undefined
}

export const CoreImage = forwardRef(function CoreImage(
  props: ImageProps,
  ref: ForwardedRef<HTMLImageElement>
) {

  const {
    title,
    src,
    href,
    className,
    width = 640,
    height = 480,
    priority = false,
    ...inputProps
  } = props

  return (
    <div className='flex w-full justify-center '>
      <Image
        ref={ref}
        alt={title}
        src={href ? IMAGE_REPOSITORY_URL.concat('/', href) : src}
        width={width}
        height={height}
        priority={priority}
        className={className}
        {...inputProps}
      />
    </div>
  )
})

export default CoreImage
